<template>
  <div class="modal fade" id="changeCostPrice" tabindex="-1" aria-labelledby="changeCostPriceLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changeCostPriceLabel">Редактировать себестоимость</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Введите себестоимость {{ changeCostPriceVal.supplierArticle }} (руб.)</p>
          <div class="d-flex pb-3">
            <input type="number" placeholder="1000" v-model="changeCostPriceVal.price" class="form-control w-50 me-3" >
            <button class="btn btn-primary my-auto" @click="changeCostPrice" data-bs-dismiss="modal">Изменить</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
      
  <div class="head-block">
    <div class="col content-title">
      <h1>Загрузка себестоимости</h1></div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">

  <div class="modal-loader d-flex justify-content-center" v-if="loader">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div class="content-block mt-3" v-else>
  <div class="alert alert-warning d-flex align-items-center mt-5" role="alert" style="width: fit-content;">
    <div>
      Загрузите файл, для этого воспользуйтесь нашим прикрепенным шаблоном, который автоматически генерируется из вашего ассортимента (артикулов)
      <br><!--<a href="https://tapwill.ru/api/cost.xlsx" target="_blank">Скачать шаблон</a>-->
      <button class="btn btn-primary mt-3"><json-excel
  :data="json_data"
  type="xlsx"
  name="cost.xls"
  :export-fields="{
    'Артикул продавца': 'Article',
    'Себестоимость': 'price'
  }"
>
  Скачать шаблон
</json-excel></button>
</div>
  </div>
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div style="width: fit-content;" v-else>
    <input type="file" id="file" ref="file" @change="onFileChange($event)" class="form-control">
    <br>
    <button class="btn btn-primary" @click="loadFile">Загрузить</button>
  </div>
</div>

 <div class="mt-5">
  <div class="row">
    <div class="col-lg-4 col-xxl-3 col-sm-6 col-12" v-for="item in items">
      
      <div class="cost_price_block p-2 mx-lg-3">
        <hr>
        <a @click="changeCostPriceVal = {price: item.price[0].price, supplierArticle: item.supplierArticle}" data-bs-toggle="modal" data-bs-target="#changeCostPrice"><i class="bi bi-pencil-square" style="position: absolute; right: 30px; font-size: 1.2em;"></i></a>
        <div class="d-flex">
          <img :src="item.img" alt="">
          <div class="ms-3">
            <b class="d-block mb-1">{{ item.supplierArticle }}</b>
            <span>WB: {{ item.nmId.wildberries }}</span>
            <span>Ozon: {{ item.nmId.ozon }}</span>
          </div>
          
        </div>

        <span class="mt-3">Себестоимость</span>

        <div class="d-flex my-1" v-for="(cost, index) in item.price" :class="{'text-decoration-line-through': index > 0}">
          <span>{{ cost.price }} руб.</span>
          <span class="ms-4">{{ cost.date }}</span>
        </div>
        <hr>
      </div>
    </div>
  </div>
 </div>
  </div>
</div>

</template>

<script>
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import JsonExcel from "vue-json-excel3";

export default{
  components: {
    JsonExcel
  },
  data(){
    return{
      xlsx: '',
      loading: false,
      json_data: '',
      items: [],
      loader: false,
      changeCostPriceVal: {
        price: 0,
        supplierArticle: ''
      }
    }
  },
  methods: {
    onFileChange(event) {
      this.xlsxfile = event.target.files ? event.target.files[0] : null
    },
    loadFile(){
      this.loading = true
      readXlsxFile(this.xlsxfile).then((rows) => {
        console.log("rows:", rows)
        axios.post('https://tapwill.ru/api/add_costprice.php', {user_id: this.$store.state.user_info.id, cost: rows}).then((response)=>{
          console.log(response)
          window.location.reload()
        })
      })
    },
    createExcelFile(){
      this.loader = true
      axios.post('https://tapwill.ru/api/get_costprice_data.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.json_data = response.data
        console.log(this.json_data)
      })
      axios.post('https://tapwill.ru/api/get_costprice_list.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.items = response.data
        this.loader = false
      })
    },

    changeCostPrice(){
      let rows=[[], [this.changeCostPriceVal.supplierArticle, String(this.changeCostPriceVal.price)]]
      axios.post('https://tapwill.ru/api/add_costprice.php', {user_id: this.$store.state.user_info.id, cost: rows}).then((response)=>{
          console.log(response)
          this.createExcelFile()
        })
    }
  },
  created(){
    this.createExcelFile()
  }
}
</script>