<template>
    <div class="main-footer" v-if="userUrl !== '/reg' && userUrl !== '/auth'">
        <footer class="footer">
            <div class="container">
            <div class="row">
                <div class="col text-center">
                2024 © TapWill
                <a href="https://tapwill.ru/docs/politics.pdf" target="_blank" class="d-block mt-2" style="font-size: 0.9em; color: #000; opacity: 0.8;">Политика конфиденциальности</a>
                <a href="https://tapwill.ru/docs/offer.pdf" target="_blank" class="d-block mt-1" style="font-size: 0.9em; color: #000; opacity: 0.8;">Публичная офферта</a>
                </div>
            </div>
            </div>
        </footer>
    </div>
    
</template>
<script>
export default{
    data(){
      return{
        userUrl: window.location.pathname
      }
    }
}
</script>
