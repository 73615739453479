<template>
    <button class="btn btn-primary my-3"><json-excel
            :fetch="loadJsonData"
            type="xlsx"
            name="items.xls"
          >
          <div class="modal-loader mt-0 d-flex justify-content-center" v-if="json_data_loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
            <span v-else>Экспорт в Excel</span>
          </json-excel></button>
</template>

<script>
import axios from 'axios'
import JsonExcel from "vue-json-excel3"

export default{
    components: {
    JsonExcel
  },
  data(){
    return{
        json_data: [],
        loadJsonData: async ()=>{
            this.json_data_loading = true
            const response = await axios.get('https://tapwill.ru/api/create_excel_parse.php')
            this.json_data = response.data
            console.log(response.data)
            this.json_data_loading = false
            return this.json_data
        },
        json_data_loading: false
    }
  }
}
</script>