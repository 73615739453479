<template>

<div class="container-fluid">

    <div class="alert alert-warning" role="alert">
      <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
    </div>

  <div class="head-block">
    <div class="content-title">
      <h1>Подписка и платежи</h1></div>
  </div>

  <div class="row mt-5">

    <div class="col-lg-4 col-md-6 mb-3">
      <div class="card yourSubscription">
        <div class="card-header">
          <h5 class="mt-2">Ваша подписка</h5></div>
        <div class="card-body">
          <div v-if="tariff !== null">
            <b>{{tariff_list[tariff['tariff']]}}</b>
            <br><small>(действует до {{ tariff['tariffTime'] }} включительно)</small>
          <div class="small mt-3">
            Осталось дней доступа: <b>{{ tariff['daysLeft'] }}</b> 
          </div>
          <div>
            <div class="mt-2" v-if="tariff['tariff'] == 0"><a href="#" class="btn btn-primary btn-sm">Купить подписку</a></div>
            <div class="mt-2" v-else><a href="#" class="btn btn-primary btn-sm">Продлить подписку</a></div>
            <div class="mt-2" v-if="$store.state.user_info.mail == 'd.makeyev@modulbank.ru'">
            <form method="post" action="https://pay.modulbank.ru/pay" accept-charset="utf-8">
              <input type="hidden" name="testing" value="0">
              <input type="hidden" name="order_id" value="client_7">
              <input type="hidden" name="amount" value="19990">
              <input type="hidden" name="merchant" value="03b9f0ea-d136-45bd-bccd-ac5479aec0c9">
              <input type="hidden" name="signature" :value="signature">
              <input type="hidden" name="description" value='Оплата подписки на сервис'>
              <input type="hidden" name="client_phone" :value="$store.state.user_info.phone">
              <input type="hidden" name="client_email" :value="$store.state.user_info.mail">
              <input type="hidden" name="success_url" value="https://tapwill.ru/tariff">
              <input type="hidden" name="receipt_contact" value="cool.andrey.02@mail.ru">
              <input type="hidden" name="unix_timestamp" :value="Math.floor(Date.now() / 1000)">
              <input type="hidden" name="receipt_items" value="[{&quot;name&quot;: &quot;Товар 2&quot;, &quot;payment_method&quot;: &quot;full_prepayment&quot;, &quot;payment_object&quot;: &quot;commodity&quot;, &quot;price&quot;: 19990, &quot;quantity&quot;: 1, &quot;sno&quot;: &quot;osn&quot;, &quot;vat&quot;: &quot;vat10&quot;}]">
              <button class="btn btn-primary btn-sm">Купить подписку ТЕСТ МОДУЛЬ БАНК</button>
            </form>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <b>Нет активных подписок</b>
          <div>
            <!--<div class="mt-2"><a href="#" class="btn btn-primary btn-sm">Купить подписку</a></div>-->
            
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-6 mb-3">
      <div class="card yourSubscription">
        <div class="card-header">
          <h5 class="mt-2">Ваш тариф</h5></div>
        <div class="card-body" v-if="orders_sum == 0">
          <p>Для расчета стоимости подписки необходимо загрузить ваш первый магазин. Это нужно для определение вашего суммарного оборота по заказам, относительно которого формируется стоимость подписки</p>
          <div>
            <div class="mt-2"><a href="/user-stores" class="btn btn-primary btn-sm">Добавить магазин</a></div>
          </div>
          </div>
        <div class="card-body" v-else>
          <p>Стоимость подписки, при суммарном обороте {{ orders_sum.toLocaleString('ru') }} руб. за 31 день </p>
          <h5 class="fw-bold">{{ tariff_sum }} <span v-if="tariff_sum!=='Бесплатно'">₽/мес</span></h5>
          <div>
            <div class="mt-2">
            <form method="post" ref="formPay" @submit.prevent="sendPayment($event)" action="https://pay.modulbank.ru/pay" accept-charset="utf-8">
              <input type="hidden" name="testing" value="0">
              <input type="hidden" name="order_id" v-model="order_id">
              <input type="hidden" name="amount" :value="tariff_sum">
              <input type="hidden" name="merchant" value="03b9f0ea-d136-45bd-bccd-ac5479aec0c9">
              <input type="hidden" name="signature" :value="signature">
              <input type="hidden" name="description" value='Оплата подписки на сервис'>
              <input type="hidden" name="client_phone" :value="$store.state.user_info.phone">
              <input type="hidden" name="client_email" :value="$store.state.user_info.mail">
              <input type="hidden" name="success_url" value="https://tapwill.ru/tariff">
              <input type="hidden" name="receipt_contact" value="cool.andrey.02@mail.ru">
              <input type="hidden" name="unix_timestamp" :value="time">
              <input type="hidden" name="receipt_items" :value="'[{&quot;name&quot;: &quot;Подписка на сервис&quot;, &quot;payment_method&quot;: &quot;full_prepayment&quot;, &quot;payment_object&quot;: &quot;commodity&quot;, &quot;price&quot;: '+tariff_sum+', &quot;quantity&quot;: 1, &quot;sno&quot;: &quot;osn&quot;, &quot;vat&quot;: &quot;vat10&quot;}]'">
              <button class="btn btn-primary btn-sm" v-if="tariff !== null" :class="{'disabled': tariff['daysLeft'] > 0}">Купить подписку</button>
               <button class="btn btn-primary btn-sm" v-else>Купить подписку</button>
            </form>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import url('../assets/style/tariff.scss');
</style>

<script>
import axios from 'axios'

export default{
  data(){
    return{
      tariff_list: ['Пробный период', 'Подписка на месяц'],
      tariff: {},
      orders_sum: 0,
      tariff_sum: 0,
      signature: '',
      time: Math.floor(Date.now() / 1000),
      formPay: this.$refs.formPay,
      order_id: this.$store.state.user_info.id +'_'+Math.floor(Date.now() / 1000)
    }
  },
  methods: {
    sendPayment(e){
      axios.post('https://tapwill.ru/api/create_signature.php', {time: this.time, user_id: this.$store.state.user_info.id, mail: this.$store.state.user_info.mail, phone: this.$store.state.user_info.phone, tariff_sum: this.tariff_sum, order_id: this.order_id}).then((response)=>{
        this.signature = response.data
        //this.formPay.value.submit()
        e.target.submit()
      })
    },
    loadData(){
      axios.post('https://tapwill.ru/api/get_tariff.php', {mail: this.$store.state.user_info.mail, user_id: this.$store.state.user_info.id}).then((response)=>{
        this.tariff = response.data[0]
        this.orders_sum = Number(response.data[1])

        if(this.orders_sum < 900000) this.tariff_sum = 'Бесплатно'
        if(this.orders_sum >= 900000) this.tariff_sum = 990
        if(this.orders_sum >= 1500000) this.tariff_sum = 2990
        if(this.orders_sum >= 3000000) this.tariff_sum = 4990
        if(this.orders_sum >= 5000000) this.tariff_sum = 9990
        if(this.orders_sum >= 10000000) this.tariff_sum = 19990

        axios.post('https://tapwill.ru/api/create_signature.php', {time: this.time, user_id: this.$store.state.user_info.id, mail: this.$store.state.user_info.mail, phone: this.$store.state.user_info.phone, tariff_sum: this.tariff_sum, order_id: this.order_id}).then((response)=>{
          this.signature = response.data
          console.log(this.signature)
        })
        console.log(this.orders_sum)
      })
    }
  },
  created(){
    this.loadData()
  }
}
</script>