<template>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
      
  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Редактирование профиля</h1></div>
  </div>
  <div class="content-block">
    <div class="row my-5">
      <div class="col-12 col-lg-6 px-lg-5 px-0">
        <div class="userProfile">
          <form  class="userProfile__form" @submit.prevent="updateUser">
            <div class="mb-3">
              <label for="email" class="form-label">E-mail</label>
              <input type="text" class="form-control" name="email" id="email" placeholder="E-mail" v-model="$store.state.user_info.mail" disabled>
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Номер телефона</label>
              <input type="text" class="form-control" name="phone" id="phone" placeholder="Номер телефона" v-model="$store.state.user_info.phone" minlength="10" maxlength="18" required>
            </div>
            <div class="mb-3">
              <label for="fullName" class="form-label">ФИО</label>
              <input type="text" class="form-control" name="fullName" id="fullName" placeholder="ФИО" v-model="$store.state.user_info.name" minlength="2" maxlength="100"required>
            </div>
            <div class="mb-3">
              <button type="submit" class="btn btn-primary">Сохранить данные</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-lg-6 px-lg-5 px-0">
        <div class="changePassword">
          <form @submit.prevent="updatePassword" class="changePassword__form">
            <div class="mb-3">
              <label for="oldPassword" class="form-label">Старый пароль</label>
              <input type="password" class="form-control" name="oldPassword" id="oldPassword" v-model="password.old" placeholder="Старый пароль" required>
            </div>
            <div class="mb-3">
              <label for="newPassword" class="form-label">Новый пароль</label>
              <input type="password" class="form-control" name="newPassword" id="newPassword" v-model="password.new" placeholder="Новый пароль" minlength="5" maxlength="30" required>
            </div>
            <div class="mb-3">
              <label for="confirmNewPassword" class="form-label">Подтвердите новый пароль</label>
              <input type="password" class="form-control" name="confirmNewPassword" v-model="password.repeat" id="confirmNewPassword" placeholder="Подтвердите новый пароль" required>
            </div>
            <div class="mb-3">
              <button type="submit" class="btn btn-primary">Изменить пароль</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 col-lg-6 px-lg-5 px-0">
        <div class="userAccountSettingTax">
          <form @submit.prevent="updateNalog" class="userAccountSettingTax__form">
            <div class="mb-3">
              <label for="email" class="form-label">Процентная ставка</label>
              <input type="number" class="form-control" name="tax" id="tax" placeholder="Процентная ставка" v-model="$store.state.user_info.nalog_percent">
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Вариант расчёта</label>
              <select class="form-control" name="tax_calculate" id="tax_calculate" v-model="$store.state.user_info.nalog_option" required>
                <option value="0">Выберите вариант расчёта</option>
                <option value="1" selected="">От выручки (от общей суммы продажи за вычетом возвратов)</option>
                <option value="2">От валовой прибыли (выручка за вычетом возвратов и себестоимости продаж)</option>
                <option value="3">От прибыли (выручка за вычетом возвратов и комиссий, не включая себестоимость)</option>
              </select>
            </div>
            <div class="mb-3">
              <button type="submit" class="btn btn-primary">Сохранить данные</button>
            </div>
          </form>
        </div>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default{
  data(){
    return{
      password: {
      }
    }
    
  },
  methods: {
    updateUser(){
      let info = {
        action: 'update_info',
        id: this.$store.state.user_info.id,
        name: this.$store.state.user_info.name, 
        phone: this.$store.state.user_info.phone
      }
      axios.post('https://tapwill.ru/api/update_profile.php', info).then((response)=>{
        if(response.data == 1 && response.status == 200){
          this.$store.commit('updateUser', {name: this.$store.state.user_info.name, phone: this.$store.state.user_info.phone})
          alert('Успешно!');
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
      
    },
    updatePassword(){
      if(this.password.old == this.$store.state.user_info.password){
        if(this.password.new == this.password.repeat){
          axios.post('https://tapwill.ru/api/update_profile.php', {action: 'update_password', id: this.$store.state.user_info.id, password: this.password.new})
          .then((response)=>{
            if(response.data == 1 && response.status == 200){
              this.$store.commit('updatePassword', this.password.new);
              this.password = {}
              alert('Успешно!');
            }
            else{
              alert('Произошла ошибка! Попробуйте позднее');
            }
          })
        }
        else{
          alert('Пароли не совпадают')
        }
      }
      else{
        alert('Неверный пароль')
      }

    },
    updateNalog(){
      let info = {
        action: 'update_nalog',
        id: this.$store.state.user_info.id,
        nalog_percent: this.$store.state.user_info.nalog_percent, 
        nalog_option: this.$store.state.user_info.nalog_option
      }
      console.log(info)
      axios.post('https://tapwill.ru/api/update_profile.php', info).then((response)=>{
        if(response.data == 1 && response.status == 200){
          this.$store.commit('updateNalog', {nalog_percent: this.$store.state.user_info.nalog_percent, nalog_option: this.$store.state.user_info.nalog_option})
          alert('Успешно!');
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
    }
  }
}
</script>