<template>
    <div class="authBg">

    <div class="account-pages pt-2 py-5">
  <div class="container">
    <div class="success_page" v-if="successReg">
      <h3 class="text-dark-50 text-center mt-0 fw-bold">Спасибо за регистрацию в сервисе TapWill!</h3>
      <p class="text-muted text-center mb-4">Используйте данные, введенные при регистрации, для авторизации в личный кабинет.</p>
      <div class="w-100 d-flex">
        <a href="/auth" class="btn btn-primary mx-auto">Войти в личный кабинет</a>
      </div>
      
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-xxl-4 col-lg-5">
        <div class="card mt-5">
          <div class="card-header pt-4 pb-4 text-center bg-primary"><a href="/" class="card-header__logo"><span>TAPWILL</span></a></div>
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <h3 class="text-dark-50 text-center mt-0 fw-bold">Создать аккаунт</h3>
              <p class="text-muted mb-4">Зарегистрируйтесь и получите бесплатный доступ ко всем функциям TAPWILL на 14 дней!</p>
            </div>
            <form id="signUp" @submit.prevent="sendForm">
              <div class="mb-3">
                <label for="fullname" class="form-label">ФИО</label>
                <input class="form-control" v-model="form_data.name" type="text" id="fullname" name="fullname" placeholder="Введите ваше фио" autocomplete="off" minlength="2" maxlength="100" required >
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Номер телефона</label>
                <input type="tel" v-model="form_data.phone" class="form-control" id="phone" name="phone" placeholder="+7(000)000-00-00" autocomplete="off" required>
              </div>
              <div class="mb-3">
                <label for="emailaddress" class="form-label">Email</label>
                <input class="form-control" v-model="form_data.mail" type="email" id="emailaddress" name="email" placeholder="Введите ваш email" autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <div class="input-group input-group-merge">
                  <input type="password" v-model="form_data.password" id="password" name="password" class="form-control" placeholder="Введите ваш пароль" minlength="5" maxlength="30" autocomplete="off">
                  
                </div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Каким способом менеджеру связаться с вами для помощи в настройке сервиса?</label>
                <div class="input-group input-group-merge">
                  <input type="text" v-model="form_data.contact" id="contact" name="contact" class="form-control" placeholder="Введите ваш whatsapp/telegram/телефон" autocomplete="off">
                </div>
              </div>
              <div class="mb-3">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="checkbox-signup" checked required>
                  <label class="form-check-label" for="checkbox-signup">Я согласен со всеми условиями <a href="/docs/rules" class="text-muted" target="_blank">пользовательского соглашения</a> и <a href="/docs/privacy" class="text-muted" target="_blank">политики конфиденциальности</a></label>
                </div>
              </div>
              <YandexSmartCaptcha 
      siteKey="ysc1_cQKG8lGlsECvdbCuro4YNa8fPLQ7XS57V6RNVllt5e3a6f97"
      @onSuccess="recaptchaVerified"
    />
              <!--<vue-recaptcha v-show="showRecaptcha" sitekey="6Lfj4W0qAAAAAPWRc3JkCWvN4zQitNg2g8FtZCWo"
		 size="normal" 
		 theme="light"
		 hl="tr"
		 :loading-timeout="loadingTimeout"
		 @verify="recaptchaVerified"
		 @expire="recaptchaExpired"
		 @fail="recaptchaFailed"
		 @error="recaptchaError"
		 ref="vueRecaptcha"/>-->
              <div class="my-3 text-center d-grid gap-2">
                <button class="btn btn-primary js-submitRegistration" type="submit"> Получить бесплатный доступ
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              Уже есть аккаунт? <a href="/auth" class="text-muted ms-1"><b>Авторизоваться</b></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</template>

<style lang="scss" scoped>
.main-menu, .main-footer{
    display: none;
}
@import url('../assets/style/auth.scss');
</style>

<script>
import axios from 'axios';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha'

export default{
  components: {
    YandexSmartCaptcha
  },
  data(){
    return{
      successCaptcha: false,
      successReg: false,
      form_data: {
        name: '',
        phone: '',
        mail: '',
        password: '',
        contact: ''
      }
      
    }
  },

  methods: {
    recaptchaVerified(response) {
      if(response){
        this.successCaptcha = true
      }
      console.log(response)
	},
	recaptchaExpired() {
	  this.$refs.vueRecaptcha.reset();
	},
	recaptchaFailed() {
	},
	recaptchaError(reason) {
	},
    sendForm(){
      if(this.successCaptcha){
      axios.post('https://tapwill.ru/api/reg.php', this.form_data).then((response)=>{
        if(response.status == 200){
          this.successReg = true
          if(response.data !== 0){
            let mess = `\*Ура, у нас новая регистрация!\*\n${this.form_data.name}\n${this.form_data.phone}\n${this.form_data.mail}\nКонтакт: ${this.form_data.contact}`;
            sendTGMessage(mess)
            function sendTGMessage(message){
              const telegramBotToken = '7243563889:AAHny5XrJT5HFWd7EYzf94p1QBBgzGuhBDI';
              const chatId = '-1002145025576';
              let data = {
                  chat_id: chatId,
                  text: message
              }
              axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({"type":"reachGoal","id":3577648,"goal":"registration"});
            }
          }
           else{
            alert('Пользователь с таким E-mail или номером телефона уже существует')
            console.log(response)
          }
        }
        else{
          alert('Произошла ошибка! Попробуйте позднее')
        }
      })
    }
    else{
      alert('Подтвердите что вы не робот')
    }
    }
  },

  mounted(){
    IMask(document.getElementById('phone'), {
            mask: '+{7}(000)000-00-00'
        });
  }
}
</script>