<template>
<div class="modal fade" id="mobile_modal_info" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mobile_modal_info">Подробная информация</h5>
        <button type="button" data-bs-dismiss="modal" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table">
          <table class="custom-table">
            <tbody v-if="items.length !== 0" class="modal-info-table">
              <tr>
                <td><img :src="items[itemId].img" alt=""></td>
              </tr>
              <tr>
                <td>Артикул продавца</td>
                <td>{{ items[itemId].article }}</td>
              </tr>
              <tr>
                <td>Артикул {{ items[itemId].marketplace }}</td>
                <td>{{ items[itemId].nmId }}</td>
              </tr>
              <tr>
                <td>Заказано, шт</td>
                <td>{{ items[itemId].orders_count }}</td>
              </tr>
              <tr>
                <td>Продано, шт</td>
                <td>{{ items[itemId].sales_count }}</td>
              </tr>
              <tr>
                <td>Отмен, шт</td>
                <td>{{ items[itemId].cancel }}</td>
              </tr>
              <tr>
                <td>Возвраты, шт</td>
                <td>{{ items[itemId].return }}</td>
              </tr>
              <tr>
                <td>Ср. заказов в день, шт</td>
                <td>{{ items[itemId].average_orders }}</td>
              </tr>
              <tr>
                <td>Процент выкупа</td>
                <td>{{ items[itemId].buyout }}%</td>
              </tr>
              <tr>
                <td>Заказы, руб.</td>
                <td>{{ items[itemId].orders_sum }}</td>
              </tr>
              <tr>
                <td>Продажи, руб.</td>
                <td>{{ items[itemId].sales_sum }}</td>
              </tr>
              <tr>
                <td>Возвраты, руб.</td>
                <td>{{ items[itemId].return_sum }}</td>
              </tr>
              <tr>
                <td>Ср. чек продажи, руб.</td>
                <td>{{ items[itemId].average_bill }}</td>
              </tr>
              <tr>
                <td>Себес-ть продаж, руб.</td>
                <td>{{ items[itemId].cost_price }}</td>
              </tr>
              <tr>
                <td>Комиссия МП, руб.</td>
                <td>{{ items[itemId].commission }}</td>
              </tr>
              <tr>
                <td>Логистика МП, руб.</td>
                <td>{{ items[itemId].logistics }}</td>
              </tr>
              <tr>
                <td>Эквайринг, руб.</td>
                <td>{{ items[itemId].acquiring }}</td>
              </tr>
              <tr>
                <td>Реклама, руб.</td>
                <td>{{ items[itemId].adv }}</td>
              </tr>
              <tr>
                <td>Хранение, руб.</td>
                <td>{{ items[itemId].storage }}</td>
              </tr>
              <tr>
                <td>Прочие расходы, руб.</td>
                <td>{{ items[itemId].penalty }}</td>
              </tr>
              <tr>
                <td>Прибыль, руб.</td>
                <td>{{ items[itemId].profit }}</td>
              </tr>
              <tr>
                <td>Ср. прибыль, руб.</td>
                <td>{{ items[itemId].average_profit }}</td>
              </tr>
              <tr>
                <td>Маржа</td>
                <td>{{ items[itemId].marginality }}%</td>
              </tr>
              <tr>
                <td>Рент-сть продукции</td>
                <td>{{ items[itemId].rent }}%</td>
              </tr>
              <tr>
                <td>ROI</td>
                <td>{{ items[itemId].roi }}%</td>
              </tr>
              <tr>
                <td>ROMI</td>
                <td>{{ items[itemId].romi }}%</td>
              </tr>
              <tr>
                <td>CPA, руб.</td>
                <td>{{ items[itemId].cpa }}</td>
              </tr>
              <tr>
                <td>ДРР (от продаж)</td>
                <td>{{ items[itemId].drr }}%</td>
              </tr>
              <tr>
                <td>Остаток, шт</td>
                <td>{{ items[itemId].stock }}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    <div class="container-fluid">
      
      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>

  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Сводка по товарам</h1></div>
  </div>
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period_mob" autocomplete="off"><span class="input-group-text"><label for="dashboard_period_mob"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off" @change="applyFilter(0)">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/itemsinfo" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period" autocomplete="off"><span class="input-group-text"><label for="dashboard_period"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" @change="applyFilter(0)">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__text mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label class="form-label">Артикул продавца</label>
                <input type="text" class="form-control" v-model="filter.article" autocomplete="off" @change="applyFilter(0)">
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter(0)">Применить</button>
              </div>
              <div class="btn-group"><a href="/itemsinfo" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

   <div v-if="$store.state.user_info.check == 0">
    <div class="progress my-5" v-if="loading">
      <div class="progress-bar" role="progressbar" :style="{'width': loadingProgress + '%'}" :aria-valuenow="loadingProgress" aria-valuemin="0" aria-valuemax="100">{{ loadingProgress }}%</div>
    </div>
  <!-- <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div> -->
  <div class="content-block" v-else>
    <button class="btn btn-primary my-3"><json-excel
            :fetch="loadJsonData"
            type="xlsx"
            name="report.xls"
            :export-fields = exportFields
          >
          <div class="modal-loader mt-0 d-flex justify-content-center" v-if="json_data_loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
            <span v-else>Экспорт в Excel</span>
          </json-excel></button>
    <div class="table">
      
      <table class="custom-table d-sm-block d-none">
        <thead>
          <tr>
            
            <!--<td rowspan="1" colspan="6">Движения товара</td>-->
            <!--<td rowspan="2">Процент<br>выкупа</td>-->
            <!--td rowspan="1" colspan="14">Финансовые показатели</td>-->
            <!--<td rowspan="2">Маржа</td>
            <td rowspan="2">Рент-сть
              <br>продукции</td>
            <td rowspan="2">ROI</td>
            <td rowspan="2">ROMI</td>
            <td rowspan="2">CPA, руб.</td>
            <td rowspan="2">ДРР</td>
            <td rowspan="2">Остаток, шт</td>-->
            
          </tr>
          <tr>
            <td rowspan="1" style="position: sticky; left: 0; background: #E6C8FF !important;">Товар</td>
            <td rowspan="1" style="cursor: pointer" @click="sort == 2 && sort_type == 0? sort = 3:sort=2; sort_type = 0; applyFilter(0)">Заказано,<br>шт
              <span v-if="sort_type == 0 && sort == 2">	&darr;</span>
              <span v-if="sort_type == 0 && sort == 3">	&uarr;</span>
            </td>
            <td rowspan="1" style="cursor: pointer" @click="sort == 2 && sort_type == 1? sort = 3:sort=2; sort_type = 1; applyFilter(0)">Продано,<br>шт
              <span v-if="sort_type == 1 && sort == 2">	&darr;</span>
              <span v-if="sort_type == 1 && sort == 3">	&uarr;</span>
            </td>
            <td rowspan="1">Отмен,
              <br>шт</td>
            <td rowspan="1">Возвраты,
              <br>шт</td>
            <td rowspan="1">Ср. заказов
              <br>в день, шт</td>
              <td rowspan="2">Процент<br>выкупа</td>
            <td rowspan="1" style="cursor: pointer" @click="sort == 0 && sort_type==0? sort = 1:sort=0; sort_type = 0; applyFilter(0)">Заказов,
              <br>руб 
              <span v-if="sort_type == 0 && sort == 0">	&darr;</span>
              <span v-if="sort_type == 0 && sort == 1">	&uarr;</span>
            </td>
            <td rowspan="1" style="cursor: pointer" @click="sort == 0 && sort_type==1? sort = 1:sort=0; sort_type = 1; applyFilter(0)">Продажи,
              <br>руб.
              <span v-if="sort_type == 1 && sort == 0">	&darr;</span>
              <span v-if="sort_type == 1 && sort == 1">	&uarr;</span>
            </td>
            <td rowspan="1">Возвраты,
              <br>руб.</td>
            <td rowspan="1">Средний чек
              <br>продажи, руб</td>
            <td rowspan="1">Себес-ть
              <br>продаж, руб.</td>
            <td rowspan="1">Комиссия
              <br>МП, руб.</td>
            <td rowspan="1">Логистика
              <br>МП, руб.</td>
            <td rowspan="1">Эквайринг,
              <br>руб.</td>
            <td rowspan="1">Реклама,
              <br>руб.</td>
            <td rowspan="1">Хранение,
              <br>руб.</td>
            <td rowspan="1">Прочие расходы,
              <br>руб.</td>
            <!-- <td rowspan="1">Налог,
              <br>руб.</td> -->
            <td rowspan="1" class="active">Прибыль,<br>руб.</td>
            <td rowspan="1">Ср. прибыль,
              <br>руб.</td>
              <td rowspan="2">Маржа</td>
            <td rowspan="2">Рент-сть
              <br>продукции</td>
            <td rowspan="2">ROI</td>
            <td rowspan="2">ROMI</td>
            <td rowspan="2">CPA, руб.</td>
            <td rowspan="2">ДРР (от продаж)</td>
            <td rowspan="2">Остаток, шт</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" class="table-light">
            <td style="position: sticky; left: 0; background: #f8f9fa;">
              <table>
                <tbody>
                  <tr class="table-light">
                    <td><img :src="item.img" alt=""></td>
                    <td><span>{{ item.article }}</span>
                      <br><span>{{ item.marketplace }}: {{ item.nmId }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>{{ item.orders_count }}</td>
            <td>{{ item.sales_count }}</td>
            <td>{{ item.cancel }}</td>
            <td>{{ item.return }}</td>
            <td>{{ item.average_orders }}</td>
            <td>{{ item.buyout }}%</td>
            <td>{{ item.orders_sum }}</td>
            
            <td>{{ item.sales_sum }}</td>
            <td>{{ item.return_sum }}</td>
            <td>{{ item.average_bill }}</td>
            <td>{{ item.cost_price }}</td>
            <td>{{ item.commission }}</td>
            <td>{{ item.logistics }}</td>
            <td>{{ item.acquiring }}</td>
            <td>{{ item.adv }}</td>
            <td>{{ item.storage }}</td>
            <td>{{ item.penalty }}</td>
            <!-- <td>0,00</td> -->
            <td>{{ item.profit }}</td>
            <td>{{ item.average_profit }}</td>
            <td>{{ item.marginality }}%</td>
            <td>{{ item.rent }}%</td>
            <td>{{ item.roi }}%</td>
            <td>{{ item.romi }}%</td>
            <td>{{ item.cpa }}</td>
            <td>{{ item.drr }}%</td>
            
            <td>{{ item.stock }}</td>
          </tr>
            
        </tbody>
        <!-- <tfoot class="d-none">
          <tr>
            <th colspan="1">Итого</th>
            <th>{{ items_result.orders_count }}</th>
            <th>{{ items_result.sales_count }}</th>
            <th>{{ items_result.cancel }}</th>
            <th>{{ items_result.return }}</th>
            <th>{{ items_result.average_orders }}</th>
            <th>{{ items_result.buyout }}%</th>
            <th>{{ items_result.orders_sum }}</th>
            <th>{{ items_result.sales_sum }}</th>
            <th>0,00</th>
            <th>{{ items_result.return_sum }}</th>
            <th>{{ items_result.average_bill }}</th>
            <th>{{ items_result.cost_price }}</th>
            <th>{{ items_result.commission }}</th>
            <th>{{ items_result.logistics }}</th>
            <th>{{ items_result.acquiring }}</th>
            <th>{{ items_result.logistics }}</th>
            <th>{{ items_result.storage }}</th>
            <th>0,00</th>
            <th>0,00</th>
            <th>{{ items_result.profit }}</th>
            <th>{{ items_result.average_profit }}</th>
            <th>{{ items_result.marginality }}%</th>
            <th>{{ items_result.rent }}%</th>
            <th>{{ items_result.roi }}%</th>
            <th>{{ items_result.romi }}%</th>
            <th>{{ items_result.cpa }}</th>
            <th>{{ items_result.drr }}%</th>
            <th>0</th>
            <th>{{ items_result.stock }}</th>
          </tr>
        </tfoot> -->
      </table>

         <table class="custom-table d-sm-none">
            <thead>
              <tr>
                <td rowspan="2" colspan="2">Товар</td>
                <td>Прибыль, руб.</td>
                <!--<td>Остаток, шт.</td>-->
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in items">
                <td><img :src="item.img" alt=""></td>
                    <td><span>{{ item.article }}</span>
                      <br><span>{{ item.marketplace }}: {{ item.nmId }}</span></td>
                <td>{{ item.profit }}</td>
                <!--<td>{{ item.stock }}</td>-->
                <td><a class="show_mobile_modal_icon" data-bs-toggle="modal" data-bs-target="#mobile_modal_info" @click="itemId = index"><i class="bi bi-eye"></i></a></td>
              </tr>
            </tbody>
          </table>
    </div>
    <div class="order-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination mt-3">
              <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                <span class="pagination-button">{{ pageNum }}</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
  </div>
</div>
</template>

<style lang="scss">
// @import '@/assets/style/items.scss';
</style>

<script>
import axios from 'axios'
import JsonExcel from "vue-json-excel3"

export default{
  props: {
    getArticle: String
  },
  components: {
    JsonExcel
  },
  data(){
    return {
      items: [],
      items_result: [],
      page: 0,
      pages_count: 0,
      filter: {
        store: '',
        brand: '',
        article: '',
        marketplace: ''
      },
      stores: [],
      brands: [],
      loading: true,
      itemId: 0,
      sort: 0,
      sort_type: 0,
      exportFields: {
        "Артикул продавца": 'article',
        "Маркетплейс": 'marketplace',
        "Артикул": 'nmId',
        "Заказано, шт": "orders_count",
        "Продано, шт": "sales_count",
        "Отмен, шт": "cancel",
        "Возвраты, шт": "return",
        "Ср. заказов в день, шт": "average_orders",
        "Процент выкупа, %": "buyout",
        "Заказы, руб.": "orders_sum",
        "Продажи, руб.": "sales_sum",
        "Возвраты, руб.": "return_sum",
        "Ср. чек продажи, руб.": "average_bill",
        "Себес-ть продаж, руб.": "cost_price",
        "Комиссия МП, руб.": "commission",
        "Логистика МП, руб.": "logistics",
        "Эквайринг, руб.": "acquiring",
        "Реклама, руб.": "adv",
        "Хранение, руб.": "storage",
        "Прочие расходы, руб.": "penalty",
        "Прибыль, руб.": "profit",
        "Ср. прибыль, руб.": "average_profit",
        "Маржа, %": "marginality",
        "Рент-сть продукции, %": "rent",
        "ROI, %": "roi",
        "ROMI, %": "romi",
        "CPA, руб.": "cpa",
        "ДРР, %": "drr",
        "Остаток, шт": "stock"
      },
      loadJsonData: async ()=>{
        this.json_data_loading = true
        let post_data = {
          start_date: $('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),
          end_date:  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'),
          store: this.filter.store,
          brand: this.filter.brand,
          article: this.filter.article,
          marketplace: this.filter.marketplace,
          user_id: this.$store.state.user_info.id,
          page: this.page,
          sort: this.sort,
          sort_type: this.sort_type
        }

        const response = await axios.post('https://tapwill.ru/api/export_items_report.php', post_data)
          this.json_data_loading = false
          return response.data
        
      },
      json_data_loading: false,
      loadingProgress: 0
    }
  },
  methods: {
    loadData(start, end){
      this.loadingProgress = 0
      this.loading = true
      let intervalTime = Number(this.$store.state.user_info.items_report_time)*10
      let intervalLoading = setInterval(()=>{
        this.loadingProgress += 1
        if(this.loadingProgress >= 98){
          clearInterval(intervalLoading)
          this.loadingProgress = 98
        }
      }, intervalTime)
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.store,
        brand: this.filter.brand,
        article: this.filter.article,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id,
        page: this.page,
        sort: this.sort,
        sort_type: this.sort_type
      }

      axios.post('https://tapwill.ru/api/get_items_report_test.php', post_data).then((response)=>{
        this.items = response.data[0]
        this.items_result = response.data[1]
        this.pages_count = response.data[2]
        console.log(response.data)
        this.loading = false
    })
    },
    applyFilter(n){
      this.page=n
      this.loadData($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    }
  },
  created(){
    if(this.$props.getArticle){
      this.filter.article = this.$props.getArticle
    }
    this.loadData(moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
    
  },
  mounted(){
    let start = moment().subtract(30, 'days');
    let end = moment();
    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.loadData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')
  }
}
</script>