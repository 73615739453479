<template>
    <div class="container-fluid">

<div class="alert alert-warning" role="alert">
  <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
</div>

<div class="head-block">
<div class="col content-title">
<h1>Акции Ozon</h1></div>
</div>
<div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

          <div v-if="$store.state.user_info.check == 0">
          <div class="modal-loader d-flex justify-content-center" v-if="loading">
              <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
          <div v-else>
    <div class="my-5 table">
        <button class="btn btn-primary my-3"><json-excel
            :fetch="loadJsonData"
            type="xlsx"
            name="акции озон.xls"
            :export-fields = exportFields
          >
          <div class="modal-loader mt-0 d-flex justify-content-center" v-if="json_data_loading">
            <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
            <span v-else>Экспорт в Excel</span>
          </json-excel></button>
        <table class="custom-table col-12">
            <thead>
                <tr>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Название акции</td>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Дата начала</td>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Дата окончания</td>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Товары для участия</td>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Товары участвуют</td>
                    <td style="font-size: 0.9em; padding: 20px 30px;">Участие</td>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in actions" :key="index">
                <tr @click="productGetInfo(item.potentional, item.part, item.action.id)" style="cursor: pointer; font-size: 1.2em;" :class="{'d-none': showInfo !== 0 && item.action.id!==showInfo, 'table-success': item.action.is_participating, 'table-warning': !item.action.is_participating}">
                    <td style="font-size: 0.7em; padding: 20px 30px; text-align: start; display: flex;"><i v-if="!showInfo" class="bi bi-plus-square d-block my-auto" style="font-size: 20px;"></i> <i v-else class="bi bi-dash-square d-block my-auto" style="font-size: 20px;"></i> <span class="d-block ms-2 my-auto">{{ item.action.title }}</span></td>
                    <td style="font-size: 0.7em; padding: 20px 30px;">{{ item.action.date_start.slice(0, 10) }}</td>
                    <td style="font-size: 0.7em; padding: 20px 30px;">{{ item.action.date_end.slice(0, 10) }}</td>
                    <td style="font-size: 0.7em; padding: 20px 30px;">{{ item.action.potential_products_count }}</td>
                    <td style="font-size: 0.7em; padding: 20px 30px;">{{ item.action.participating_products_count }}</td>
                    <td style="font-size: 0.7em; padding: 20px 30px;" v-if="item.action.is_participating ">Да</td>
                    <td style="font-size: 0.7em; padding: 20px 30px;" v-else>Нет</td>
                </tr>

 
                <tr v-if="showInfo == item.action.id">
                    <div style="position: absolute; top: 40%; left: 58vw;" class="modal-loader d-flex justify-content-center" v-if="loading2">
                        <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
                    </div>
                <td colspan="6" v-else>
                <div class="table">
                    <table class="custom-table mt-4" :class="{'d-block': Object.keys(productInfo).length < (item.potentional.length + item.part.length)}">
                        <thead>
                            <tr style="position: sticky; top: 0;">
                                <td colspan="2" rowspan="2" style="border-top-left-radius: 0;">Товар</td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='quantity' ? sort.order='ASC' : sort.order='DESC'; sort.type='quantity'; sortItems(index)" :class="{'fw-bold': sort.type == 'quantity' }">
                                    Остаток <i v-if="sort.type !== 'quantity'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'quantity' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'quantity' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='realisation' ? sort.order='ASC' : sort.order='DESC'; sort.type='realisation'; sortItems(index)" :class="{'fw-bold': sort.type == 'realisation' }">
                                    Оборачиваемость (дней) <i v-if="sort.type !== 'realisation'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'realisation' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'realisation' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='price' ? sort.order='ASC' : sort.order='DESC'; sort.type='price'; sortItems(index)" :class="{'fw-bold': sort.type == 'price' }">
                                    Текущая цена <i v-if="sort.type !== 'price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'price' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer; border-top-right-radius: 0;" @click="sort.order=='DESC' && sort.type=='price_index' ? sort.order='ASC' : sort.order='DESC'; sort.type='price_index'; sortItems(index)" :class="{'fw-bold': sort.type == 'price_index' }">
                                    Индекс текущей цены <i v-if="sort.type !== 'price_index'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'price_index' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'price_index' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='max_action_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='max_action_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'max_action_price' }">
                                    Цена по акции <i v-if="sort.type !== 'max_action_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'max_action_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'max_action_price' && sort.order == 'ASC'"><i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='action_price_index' ? sort.order='ASC' : sort.order='DESC'; sort.type='action_price_index'; sortItems(index)" :class="{'fw-bold': sort.type == 'action_price_index' }">
                                    Индекс цены по акции <i v-if="sort.type !== 'action_price_index'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'action_price_index' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'action_price_index' && sort.order == 'ASC'"><i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer; border-top-right-radius: 0;" @click="sort.order=='DESC' && sort.type=='minimal_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='minimal_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'minimal_price' }">
                                    Мин. цена на др. площадке <i v-if="sort.type !== 'minimal_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'minimal_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'minimal_price' && sort.order == 'ASC'"><i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='price_low' ? sort.order='ASC' : sort.order='DESC'; sort.type='price_low'; sortItems(index)" :class="{'fw-bold': sort.type == 'price_low' }">
                                    Снижение от текущей цены (%) <i v-if="sort.type !== 'price_low'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'price_low' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'price_low' && sort.order == 'ASC'"><i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='cost_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='cost_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'cost_price' }">
                                    Себестоимость <i v-if="sort.type !== 'cost_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'cost_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'cost_price' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='logistics' ? sort.order='ASC' : sort.order='DESC'; sort.type='logistics'; sortItems(index)" :class="{'fw-bold': sort.type == 'logistics' }">
                                    Ср. логистика <i v-if="sort.type !== 'logistics'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'logistics' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'logistics' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='acquiring' ? sort.order='ASC' : sort.order='DESC'; sort.type='acquiring'; sortItems(index)" :class="{'fw-bold': sort.type == 'acquiring' }">
                                    Ср. эквайринг <i v-if="sort.type !== 'acquiring'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'acquiring' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'acquiring' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='commision_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='commision_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'commision_price' }">
                                    Комиссия по акции <i v-if="sort.type !== 'commision_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'commision_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'commision_price' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='profit' ? sort.order='ASC' : sort.order='DESC'; sort.type='profit'; sortItems(index)" :class="{'fw-bold': sort.type == 'profit' }">
                                    Прибыль за ед. по тек. цене <i v-if="sort.type !== 'profit'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'profit' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'profit' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='action_profit' ? sort.order='ASC' : sort.order='DESC'; sort.type='action_profit'; sortItems(index)" :class="{'fw-bold': sort.type == 'action_profit' }">
                                    Прибыль за ед. по акц. цене <i v-if="sort.type !== 'action_profit'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'action_profit' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'action_profit' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='rent_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='rent_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'rent_price' }">
                                    Рент. товара по тек. цене (%) <i v-if="sort.type !== 'rent_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'rent_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'rent_price' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='marg_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='marg_price'; sortItems(index)" :class="{'fw-bold': sort.type == 'marg_price' }">
                                    Марж. товара по тек. цене (%) <i v-if="sort.type !== 'marg_price'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'marg_price' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'marg_price' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='rent_action' ? sort.order='ASC' : sort.order='DESC'; sort.type='rent_action'; sortItems(index)" :class="{'fw-bold': sort.type == 'rent_action' }">
                                    Рент. товара по акции (%) <i v-if="sort.type !== 'rent_action'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'rent_action' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'rent_action' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                                <td style="cursor: pointer" @click="sort.order=='DESC' && sort.type=='marg_action' ? sort.order='ASC' : sort.order='DESC'; sort.type='marg_action'; sortItems(index)" :class="{'fw-bold': sort.type == 'marg_action' }">
                                    Марж. товара по акции (%) <i v-if="sort.type !== 'marg_action'" class="bi bi-sort-down"></i>
                                    <span v-if="sort.type == 'marg_action' && sort.order == 'DESC'"><i class="bi bi-sort-down"></i></span>
                                    <span v-if="sort.type == 'marg_action' && sort.order == 'ASC'">	<i class="bi bi-sort-up-alt"></i></span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productList" :id="product.id" :class="{'table-warning': product.status == 0, 'table-success': product.status == 1}">
                                <td>
                                    <img :src="product.img" style="max-width: 30px;">
                                </td>
                                <td class="text-start"><b>{{ product.supplierArticle }}</b></td>
                                <td>{{ product.quantity }}</td>
                                <td>{{ product.realisation }}</td>
                                <td>{{ product.price }}</td>
                                <td>{{ product.price_index }}</td>
                                <td>
                                    <span v-if="product.status == 0">{{ product.max_action_price }}</span>
                                    <span v-if="product.status == 1">{{ product.action_price }}</span>
                                </td>
                                <td>{{ product.action_price_index }}</td>
                                <td>{{ product.minimal_price }}</td>
                                <td>{{ product.price_low }}%</td>
                                <td>{{ product.cost_price }}</td>
                                <td>{{ product.logistics }}</td>
                                <td>{{ product.acquiring }}</td>
                                <td>{{ product.commision_price }}</td>
                                <td>{{ product.profit }}</td>
                                <td>{{ product.action_profit }}</td>
                                <td>{{ product.rent_price }}</td>
                                <td>{{ product.marg_price }}</td>
                                <td>{{ product.rent_action }}</td>
                                <td>{{ product.marg_action }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </td>
                </tr>
                </template>
            </tbody>

        </table>
    </div>
    </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import JsonExcel from "vue-json-excel3"

export default{
    components: {
    JsonExcel
  },
    data(){
        return{
            actions: [],
            loading: false,
            productInfo: {},
            showInfo: 0,
            sort: {
                type: '',
                order: 'DESC'
            },
            productList: [],
            loading2: false,
            exportFields: {
                "Название акции": "action_title",
                "Статус": "status",
                "Товар": "supplierArticle",
                "Остаток": "quantity",
                "Оборачиваемость (дней)": "realisation",
                "Текущая цена": "price",
                "Индекс текущей цены": "price_index",
                "Цена по акции (участв.)": "action_price",
                "Цена по акции (макс.)": "max_action_price",
                "Индекс цены по акции": "action_price_index",
                "Мин. цена на др. площадке": "minimal_price",
                "Снижение от текущей цены": "price_low",
                "Себестоимость": "cost_price",
                "Ср. логистика": "logistics",
                "Ср. эквайринг": "acquiring",
                "Комиссия по акции": "commision_price",
                "Прибыль за ед. по тек. цене": "profit",
                "Прибыль за ед. по акц. цене": "action_profit",
                "Рент. товара по тек. цене": "rent_price",
                "Марж. товара по тек. цене": "marg_price",
                "Рент. товара по акции": "rent_action",
                "Марж. товара по акции": "marg_action"
            },
            loadJsonData: async ()=>{
                this.json_data_loading = true
                let productListExcel = []
                const actions_ozon = await axios.post('https://tapwill.ru/api/test_actions_ozon.php', {user_id: this.$store.state.user_info.id})
                
                for(const action of actions_ozon.data) {
                    const response = await axios.post('https://tapwill.ru/api/get_actions_ozon_products_all.php',  {user_id: this.$store.state.user_info.id})
                    
                    let id_part_list = action.part
                    let id_pot_list = action.potentional
                    for(let i=0; i<id_part_list.length; i++){
                    const element = id_part_list[i];
                            if(response.data[element.id]){
                                let productInfoExcel = response.data[String(element.id)]
                            productInfoExcel = response.data[element.id]
                            if(productInfoExcel.realisation == "-1") productInfoExcel.realisation = "Нет продаж за 30 дней"
                            if(productInfoExcel.price_index == "0") productInfoExcel.price_index = "Без индекса"
                            
                            if(productInfoExcel.price > 0) productInfoExcel.price_low = parseInt(((productInfoExcel.price - element.action_price) / productInfoExcel.price) * 100)
                                else productInfoExcel.price_low = 'Нет цены'

                                if(productInfoExcel.minimal_price > 0) productInfoExcel.action_price_index = ((1-(productInfoExcel.price_low/100))*productInfoExcel.price_index).toFixed(2)
                                else productInfoExcel.action_price_index = 'Нет индекса'

                                
                                productInfoExcel.commision_price = parseInt(element.action_price * (productInfoExcel.commision/100))

                                productInfoExcel.profit = parseInt(productInfoExcel.price - productInfoExcel.logistics - productInfoExcel.acquiring - productInfoExcel.cost_price - (productInfoExcel.price * (productInfoExcel.commision/100)))

                                productInfoExcel.action_profit = parseInt(element.action_price - productInfoExcel.logistics - productInfoExcel.acquiring - productInfoExcel.cost_price - (element.action_price * (productInfoExcel.commision/100)))

                                if(productInfoExcel.cost_price > 0) productInfoExcel.rent_price = parseInt(parseFloat(productInfoExcel.profit/productInfoExcel.cost_price)*100)
                                else productInfoExcel.rent_price = 'Добавьте себестоимость'

                                if(productInfoExcel.price > 0) productInfoExcel.marg_price = parseInt(parseFloat(productInfoExcel.profit/productInfoExcel.price)*100)
                                else productInfoExcel.marg_price = 'Нет цены'

                                if(productInfoExcel.cost_price > 0) productInfoExcel.rent_action = parseInt(parseFloat(productInfoExcel.action_profit/productInfoExcel.cost_price)*100)
                                else productInfoExcel.rent_action = 'Добавьте себестоимость'

                                if(productInfoExcel.price > 0) productInfoExcel.marg_action = parseInt(parseFloat(productInfoExcel.action_profit/element.action_price)*100)
                                else productInfoExcel.marg_action = 'Нет цены'

                                element.status = 'Участвует'
                                element.action_title = action.action.title
                                delete productInfoExcel.id
                                const productInfoConst = Object.assign(productInfoExcel, element)
                                productListExcel.push(productInfoConst)
                               // productInfoExcel = {}
                            }


                }

                for(let i=0; i<id_pot_list.length; i++){
                    const element = id_pot_list[i];
                            if(response.data[String(element.id)]){
                            let productInfoExcel = response.data[String(element.id)]
                            
                            if(productInfoExcel.realisation == "-1") productInfoExcel.realisation = "Нет продаж за 30 дней"
                            if(productInfoExcel.price_index == "0") productInfoExcel.price_index = "Без индекса"
                            
                            if(productInfoExcel.price > 0) productInfoExcel.price_low = parseInt(((productInfoExcel.price - element.max_action_price) / productInfoExcel.price) * 100)
                                                else productInfoExcel.price_low = 'Нет цены'

                                                if(productInfoExcel.minimal_price > 0) productInfoExcel.action_price_index = ((1-(productInfoExcel.price_low/100))*productInfoExcel.price_index).toFixed(2)
                                                else productInfoExcel.action_price_index = 'Нет индекса'

                                                productInfoExcel.commision_price = parseInt(element.max_action_price * (productInfoExcel.commision/100))

                                                productInfoExcel.profit = parseInt(productInfoExcel.price - productInfoExcel.logistics - productInfoExcel.acquiring - productInfoExcel.cost_price - (productInfoExcel.price * (productInfoExcel.commision/100)))

                                                productInfoExcel.action_profit = parseInt(element.max_action_price - productInfoExcel.logistics - productInfoExcel.acquiring - productInfoExcel.cost_price - (element.max_action_price * (productInfoExcel.commision/100)))

                                                if(productInfoExcel.cost_price > 0) productInfoExcel.rent_price = parseInt(parseFloat(productInfoExcel.profit/productInfoExcel.cost_price)*100)
                                                else productInfoExcel.rent_price = 'Добавьте себестоимость'

                                                if(productInfoExcel.price > 0) productInfoExcel.marg_price = parseInt(parseFloat(productInfoExcel.profit/productInfoExcel.price)*100)
                                                else productInfoExcel.marg_price = 'Нет цены'

                                                if(productInfoExcel.cost_price > 0) productInfoExcel.rent_action = parseInt(parseFloat(productInfoExcel.action_profit/productInfoExcel.cost_price)*100)
                                                else productInfoExcel.rent_action = 'Добавьте себестоимость'

                                                if(productInfoExcel.price > 0) productInfoExcel.marg_action = parseInt(parseFloat(productInfoExcel.action_profit/element.max_action_price)*100)
                                                else productInfoExcel.marg_action = 'Нет цены'

                                element.status = 'Не участвует'
                                element.action_title = action.action.title
                                delete productInfoExcel.id

                                const productInfoConst = Object.assign(productInfoExcel, element)
                                //productListExcel.push(element)
                                productListExcel.push(productInfoConst)
                                //productInfoExcel = {}
                            }
                            


                }
                console.log(productListExcel)
            }
                
                this.json_data_loading = false
                
                return productListExcel
                
            },
            json_data_loading: false,
        }
    },
    methods:{
        loadData(){
            this.loading = true
            console.log(this.$store.state.user_info)
            axios.post('https://tapwill.ru/api/test_actions_ozon.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
                this.actions=response.data
                this.loading = false
                console.log(response.data)
                
            })
        },
        productGetInfo(id_pot_list, id_part_list, id_action){
            
            this.sort = {
                type: 'realisation',
                order: 'DESC'
            }

            if(this.showInfo == 0){
                this.showInfo = id_action

            axios.post('https://tapwill.ru/api/get_actions_ozon_products_all.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
                this.loading2 = true
                for(let i=0; i<id_part_list.length; i++){
                    let element = id_part_list[i];
                            if(response.data[element.id]){
                            this.productInfo[String(element.id)] = response.data[element.id]
                            if(this.productInfo[String(element.id)].realisation == "-1") this.productInfo[String(element.id)].realisation = "Нет продаж за 30 дней"
                            if(this.productInfo[String(element.id)].price_index == "0") this.productInfo[String(element.id)].price_index = "Без индекса"
                            
                            if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].price_low = parseInt(((this.productInfo[String(element.id)].price - element.action_price) / this.productInfo[String(element.id)].price) * 100)
                                else this.productInfo[String(element.id)].price_low = 'Нет цены'

                                if(this.productInfo[String(element.id)].minimal_price > 0) this.productInfo[String(element.id)].action_price_index = ((1-(this.productInfo[String(element.id)].price_low/100))*this.productInfo[String(element.id)].price_index).toFixed(2)
                                else this.productInfo[String(element.id)].action_price_index = 'Нет индекса'

                                
                                this.productInfo[String(element.id)].commision_price = parseInt(element.action_price * (this.productInfo[String(element.id)].commision/100))

                                this.productInfo[String(element.id)].profit = parseInt(this.productInfo[String(element.id)].price - this.productInfo[String(element.id)].logistics - this.productInfo[String(element.id)].acquiring - this.productInfo[String(element.id)].cost_price - (this.productInfo[String(element.id)].price * (this.productInfo[String(element.id)].commision/100)))

                                this.productInfo[String(element.id)].action_profit = parseInt(element.action_price - this.productInfo[String(element.id)].logistics - this.productInfo[String(element.id)].acquiring - this.productInfo[String(element.id)].cost_price - (element.action_price * (this.productInfo[String(element.id)].commision/100)))

                                if(this.productInfo[String(element.id)].cost_price > 0) this.productInfo[String(element.id)].rent_price = parseInt(parseFloat(this.productInfo[String(element.id)].profit/this.productInfo[String(element.id)].cost_price)*100)
                                else this.productInfo[String(element.id)].rent_price = 'Добавьте себестоимость'

                                if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].marg_price = parseInt(parseFloat(this.productInfo[String(element.id)].profit/this.productInfo[String(element.id)].price)*100)
                                else this.productInfo[String(element.id)].marg_price = 'Нет цены'

                                if(this.productInfo[String(element.id)].cost_price > 0) this.productInfo[String(element.id)].rent_action = parseInt(parseFloat(this.productInfo[String(element.id)].action_profit/this.productInfo[String(element.id)].cost_price)*100)
                                else this.productInfo[String(element.id)].rent_action = 'Добавьте себестоимость'

                                if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].marg_action = parseInt(parseFloat(this.productInfo[String(element.id)].action_profit/element.action_price)*100)
                                else this.productInfo[String(element.id)].marg_action = 'Нет цены'

                                element.status = 1
                                delete this.productInfo[String(element.id)].id
                                element = Object.assign(this.productInfo[String(element.id)], element)
                                this.productList.push(this.productInfo[String(element.id)])
                            }


                }

                for(let i=0; i<id_pot_list.length; i++){
                    let element = id_pot_list[i];
                            if(response.data[String(element.id)]){
                            this.productInfo[String(element.id)] = response.data[element.id]
                            console.log(this.productInfo)
                            if(this.productInfo[String(element.id)].realisation == "-1") this.productInfo[String(element.id)].realisation = "Нет продаж за 30 дней"
                            if(this.productInfo[String(element.id)].price_index == "0") this.productInfo[String(element.id)].price_index = "Без индекса"
                            
                            if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].price_low = parseInt(((this.productInfo[String(element.id)].price - element.max_action_price) / this.productInfo[String(element.id)].price) * 100)
                                                else this.productInfo[String(element.id)].price_low = 'Нет цены'

                                                if(this.productInfo[String(element.id)].minimal_price > 0) this.productInfo[String(element.id)].action_price_index = ((1-(this.productInfo[String(element.id)].price_low/100))*this.productInfo[String(element.id)].price_index).toFixed(2)
                                                else this.productInfo[String(element.id)].action_price_index = 'Нет индекса'

                                                this.productInfo[String(element.id)].commision_price = parseInt(element.max_action_price * (this.productInfo[String(element.id)].commision/100))

                                                this.productInfo[String(element.id)].profit = parseInt(this.productInfo[String(element.id)].price - this.productInfo[String(element.id)].logistics - this.productInfo[String(element.id)].acquiring - this.productInfo[String(element.id)].cost_price - (this.productInfo[String(element.id)].price * (this.productInfo[String(element.id)].commision/100)))

                                                this.productInfo[String(element.id)].action_profit = parseInt(element.max_action_price - this.productInfo[String(element.id)].logistics - this.productInfo[String(element.id)].acquiring - this.productInfo[String(element.id)].cost_price - (element.max_action_price * (this.productInfo[String(element.id)].commision/100)))

                                                if(this.productInfo[String(element.id)].cost_price > 0) this.productInfo[String(element.id)].rent_price = parseInt(parseFloat(this.productInfo[String(element.id)].profit/this.productInfo[String(element.id)].cost_price)*100)
                                                else this.productInfo[String(element.id)].rent_price = 'Добавьте себестоимость'

                                                if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].marg_price = parseInt(parseFloat(this.productInfo[String(element.id)].profit/this.productInfo[String(element.id)].price)*100)
                                                else this.productInfo[String(element.id)].marg_price = 'Нет цены'

                                                if(this.productInfo[String(element.id)].cost_price > 0) this.productInfo[String(element.id)].rent_action = parseInt(parseFloat(this.productInfo[String(element.id)].action_profit/this.productInfo[String(element.id)].cost_price)*100)
                                                else this.productInfo[String(element.id)].rent_action = 'Добавьте себестоимость'

                                                if(this.productInfo[String(element.id)].price > 0) this.productInfo[String(element.id)].marg_action = parseInt(parseFloat(this.productInfo[String(element.id)].action_profit/element.max_action_price)*100)
                                                else this.productInfo[String(element.id)].marg_action = 'Нет цены'

                                element.status = 0
                                delete this.productInfo[String(element.id)].id
                                element = Object.assign(this.productInfo[String(element.id)], element)
                                this.productList.push(this.productInfo[String(element.id)])
                                

                                
                            }
                            


                }
                console.log(this.productList)
                
                this.sortItems()

                this.loading2 = false
            })    
                
            }
            else{
                this.showInfo = 0
                this.productList = []
            }
        },

        sortItems(){
            if(this.sort.order == 'DESC'){
                this.productList.sort((a, b) =>{
                    if(!isNaN(parseFloat(a[this.sort.type]))  && !isNaN(parseFloat(b[this.sort.type]))) return parseFloat(b[this.sort.type]) - parseFloat(a[this.sort.type])
                    if(isNaN(parseFloat(a[this.sort.type]))) return false
                    if(isNaN(parseFloat(b[this.sort.type]))) return true
                    
                })
            }
            if(this.sort.order == 'ASC'){
                this.productList.sort((a, b) =>{
                    if(!isNaN(parseFloat(a[this.sort.type]))  && !isNaN(parseFloat(b[this.sort.type]))) return parseFloat(a[this.sort.type]) - parseFloat(b[this.sort.type])
                    if(isNaN(parseFloat(a[this.sort.type]))) return true
                    if(isNaN(parseFloat(b[this.sort.type]))) return false
                })
            }
            }
    },
    created(){
        this.loadData()
    }
}
</script>