<template>
    <div class="authBg">
        <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-lg-5">
        <div class="card">
          <div class="card-header pt-4 pb-4 text-center bg-primary"><a href="/" class="card-header__logo"><span>TAPWILL</span></a></div>
          <div class="card-body p-4">
            <div class="text-center w-100 m-auto">
              <h3 class="text-dark-50 text-center mt-0 fw-bold">Забыли пароль?</h3>
              <p class="text-muted mb-4">Введите свой email, указанный при регистрации. Вышлем ссылку для сброса пароля.</p>
            </div>
            <form id="forgotPassword" v-if="checkUserCode" @submit.prevent="changePassword">
              <div class="mb-3">
                <input class="form-control" type="password" v-model="password" placeholder="Введите новый пароль" minlength="5" maxlength="30" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-primary"> Подтвердить
                  </button>
                </div>
              </div>
            </form>
            <form id="forgotPassword" v-if="codeSended && !checkUserCode" @submit.prevent="checkCode">
              <div class="mb-3">
                <input class="form-control" type="text" v-model="userCode" placeholder="Введите код" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-primary"> Подтвердить
                  </button>
                </div>
              </div>
            </form>
            <form id="forgotPassword" v-if="!codeSended && !checkUserCode" @submit.prevent="sendCode">
              <div class="mb-3">
                <input class="form-control" type="email" v-model="mail" placeholder="Введите email аккаунта" required>
              </div>
              <div class="mb-3 text-center">
                <div class="d-grid gap-2">
                  <button class="btn btn-primary"> Восстановить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted"><a href="/auth" class="text-muted ms-1"><b>Авторизоваться</b></a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
</template>

<style lang="scss" scoped>
.main-menu, .main-footer{
    display: none;
}
@import url('../assets/style/auth.scss');
</style>

<script>
import axios from 'axios'
export default{
  data(){
    return{
      mail: '',
      codeSended: false,
      userCode: '',
      checkUserCode: false,
      userId: 0,
      password: ''
    }
  },
  methods: {
    sendCode(){
      axios.post('https://tapwill.ru/api/send_code.php', {mail: this.mail}).then((response)=>{
        if(response.data !== 0){
          this.codeSended = true
        }
        else{
          alert('Неверный email')
        }
      })
    },
    checkCode(){
      axios.post('https://tapwill.ru/api/check_code.php', {mail: this.mail, code: this.userCode}).then((response)=>{
        if(response.data !== 0 && response.status == 200){
          this.checkUserCode = true
          this.userId = response.data
          console.log(this.userId)
        }
        else{
          alert('Неверный код!')
          window.location.reload()
        }
      })
    },
    changePassword(){
        axios.post('https://tapwill.ru/api/update_profile.php', {action: 'update_password', id: this.userId, password: this.password})
          .then((response)=>{
            if(response.data == 1 && response.status == 200){
              
              alert('Успешно!');
              window.location.href = '/auth'
            }
            else{
              alert('Произошла ошибка! Попробуйте позднее');
            }
          })
    }
    }
  }
</script>