<template>
    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>
      
  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Сводка по прибыли</h1></div>
  </div>
  
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period_mob" autocomplete="off"><span class="input-group-text"><label for="dashboard_period_mob"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="14.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="14.03.2024">
          </div>
          <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/profit" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period" autocomplete="off"><span class="input-group-text"><label for="dashboard_period"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="14.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="14.03.2024">
          </div>
          <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" @change="applyFilter">
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/profit" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>
  <div class="content-block mt-5" v-else>
    <table border="1" cellpadding="0" cellspacing="0" class="table-striped table-bordered table-hover profit_table mx-auto">
      <thead>
        <tr class="bg-fill">
          <th class="bold-text">&nbsp;</th>
          <th class="bold-text">Всего</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-fill">
          <td class="bold-text" @click="revenue = !revenue"><i class="bi" :class="{'bi-dash-circle':revenue, 'bi-plus-circle':!revenue}"></i> Выручка</td>
          <td class="bold-text" style="text-align: right;">{{ financial_data.gain }} р.</td>
        </tr>
        <tr  v-if="revenue">
          <td style="font-style: italic">Продажи</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.sales }} р.</td>
        </tr>
        <tr  v-if="revenue">
          <td style="font-style: italic">Доплаты</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.extra_payments }} р.</td>
        </tr>
        <tr  v-if="revenue">
          <td style="font-style: italic">Возвраты</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.return }} р.</td>
        </tr>
        <tr  v-if="revenue">
          <td style="font-style: italic">Доплаты по браку</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.defective }} р.</td>
        </tr>
        <tr>
          <td>Себестоимость проданных товаров</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.cost_price }} р.
          </td>
        </tr>
        <tr class="bg-fill">
          <td class="bold-text">Маржинальная прибыль</td>
          <td class="bold-text" style="text-align: right;">{{ financial_data.marginality }} р.</td>
        </tr>
        <tr class="bg-fill">
          <td @click="direct_expenses = !direct_expenses"><i class="bi" :class="{'bi-dash-circle':direct_expenses, 'bi-plus-circle':!direct_expenses}"></i> Расходы на реализацию</td>
          <td style="text-align: right;">{{ financial_data.expenses }} р.
          </td>
        </tr>
        <tr class="" v-if="direct_expenses">
          <td style="font-style: italic">Комиссия МП</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.commission }} р.
          </td>
        </tr>
        <tr class="" v-if="direct_expenses">
          <td style="font-style: italic">Логистика</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.logistics }} р.
          </td>
        </tr>
        <tr class="" v-if="direct_expenses">
          <td style="font-style: italic">Эквайринг</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.acquiring }} р.
          </td>
        </tr>
        <tr class="" v-if="direct_expenses">
          <td style="font-style: italic">Внутренняя реклама маркетплейсов</td>
          <td style="text-align: right;font-style: italic">{{ financial_data.adv }} р.
          </td>
        </tr>
        <tr class="" v-if="direct_expenses">
          <td style="font-style: italic">Списание с баланса (реклама)</td>
          <td style="text-align: right;font-style: italic">({{ financial_data.adv_balance }} р.)
          </td>
        </tr>
        <tr v-if="direct_expenses">
          <td style="font-style: italic;">Хранение на складах</td>
          <td style="font-style: italic; text-align: right;">{{ financial_data.storage }} р.
          </td>
        </tr>
        <tr v-if="direct_expenses">
          <td style="font-style: italic;">Штрафы</td>
          <td style="font-style: italic; text-align: right;">{{ financial_data.penalty }} р.
          </td>
        </tr>
        <tr v-if="direct_expenses">
          <td style="font-style: italic;">Платная приемка</td>
          <td style="font-style: italic; text-align: right;">{{ financial_data.acceptance }} р.
          </td>
        </tr>
        <tr class="bg-fill">
          <td class="bold-text">Операционная прибыль</td>
          <td class="bold-text" style="text-align: right;">{{ financial_data.oper }} р.</td>
        </tr>
        <tr>
          <td>Рентабельность ОП</td>
          <td style="text-align: right;">{{ financial_data.oper_rent }}%</td>
        </tr>
        <tr class="bg-fill">
          <td class="bold-text">Чистая прибыль</td>
          <td class="bold-text" style="text-align: right;">{{ financial_data.profit }} р.</td>
        </tr>
        <!-- <tr>
          <td>Налоги</td>
          <td style="text-align: right;">{{ financial_data.tax }} р.</td>
        </tr> -->
      </tbody>
    </table>
  </div>
  </div>
</div>
</template>

<style lang="scss">
/* @import url('../assets/style/profitreport.scss'); */
.bi{
    cursor: pointer;
}
</style>

<script>
import axios from 'axios';

export default{
    data(){
        return{
            revenue: false,
            direct_expenses: true,
            commercial_expenses: false,
            marketplace_expenses: false,
            stores: [],
            brands: [],
            filter: {
              stores: '',
              brands: '',
              marketplace: ''
            },
            financial_data: {},
            loading: true
        }
    },
    methods: {
      updateInfo(start, end){
        this.loading = true
        let post_data = {
          start_date: start,
          end_date: end,
          store: this.filter.stores,
          brand: this.filter.brands,
          marketplace: this.filter.marketplace,
          user_id: this.$store.state.user_info.id
        }
        axios.post('https://tapwill.ru/api/get_finance_data.php', post_data).then((response)=>{
          console.log(response.data)
          this.financial_data = response.data
          this.loading = false
        });
      },
      applyFilter(){
        this.updateInfo($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
      }
    },
    created(){
      this.updateInfo(moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
      axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.stores = response.data
        console.log(this.stores)
      })
      axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
        this.brands = response.data
        console.log(this.brands)
      })
    },
    mounted(){

      let start = moment().subtract(30, 'days');
      let end = moment();

      function cb(start, end) {
          $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      }

      $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.updateInfo(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


      cb(start, end);

      $('li[data-range-key="Custom Range"]').text('Произвольно')

    }
}
</script>