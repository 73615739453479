<template>
    <div class="container-fluid">
      
      <div class="alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 0.9em;">Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
      </div>

  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>Рекламная кампания <u>{{ id }}</u></h1>
      <a href="/advert/list" class="btn btn-outline-primary btn-sm mb-5">К списку РК</a>
    </div>
  </div>
  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

<div v-if="$store.state.user_info.check == 0">
  <div class="content-block col-xl-3 col-lg-3 col-md-6 col-sm-12">
      <p>Название: <b>{{ campaign_data.name }}</b></p>
      <p>Тип: <b>{{ campaign_data.type }}</b></p>
      <p>Статус: <b>{{ campaign_data.status }}</b></p>
      <p>Дата создания: {{ campaign_data.createTime }}</p>
      <p>Дата изменения: {{ campaign_data.changeTime }}</p>
      <p>Дата начала: {{ campaign_data.startTime }}</p>
      <p>Дата окончания: {{ campaign_data.endTime }}</p>
      <p>Магазин: <b>{{ campaign_data.store }}</b></p>
      <p>Дневной бюджет: {{ campaign_data.dailyBudget }} ₽</p>
  </div>
  <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div>

  <div class="content-block my-5" v-else>
    <h3 class="mb-3">Статистика по артикулам</h3>
    <div class="table">
      <table class="custom-table">
        <thead>
          <tr>
            <td>Артикул</td>
            <td>Показы</td>
            <td>Клики</td>
            <td>Добавлено
              <br>в корзину</td>
            <td>Оформлено
              <br>заказов</td>
            <td>Сумма
              <br>заказов, ₽</td>
            <td>Средняя
              <br>CPM, ₽</td>
            <td>Расход, ₽</td>
            <td>CTR, %</td>
            <td>CPC, ₽</td>
            <td>CR, %</td>
            <td>CPA, ₽</td>
            <td>Бренд</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items_articles" class="table-light">
            <td>
              {{ item.supplierArticle }}
              <br>Артикул МП: {{ item.nmId }}
            </td>
            <td>{{ item.views }}</td>
            <td>{{ item.clicks }}</td>
            <td>{{ item.atbs }}</td>
            <td>{{ item.orders }}</td>
            <td>{{ item.sum_price }}</td>
            <td>{{ item.cpm }}</td>
            <td>{{ item.sum }}</td>
            <td>{{ item.ctr }}</td>
            <td>{{ item.cpc }}</td>
            <td>{{ item.cr }}</td>
            <td>{{ item.cpa }}</td>
            <td>{{ item.brand }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>

    <div class="content-block my-5" v-if="!loading">
    <h3 class="mb-3">Статистика по дням</h3>
    <div class="table">
      <table class="custom-table">
        <thead>
          <tr>
            <td>Дата</td>
            <td>Показы</td>
            <td>Клики</td>
            <td>Добавлено
              <br>в корзину</td>
            <td>Оформлено
              <br>заказов</td>
            <td>Сумма
              <br>заказов, ₽</td>
            <td>Средняя
              <br>CPM, ₽</td>
            <td>Расход, ₽</td>
            <td>CTR, %</td>
            <td>CPC, ₽</td>
            <td>CR, %</td>
            <td>CPA, ₽</td>
            <td>Источник показа</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items_days" class="table-light">
            <td>{{ item.date }}</td>
            <td>{{ item.views }}</td>
            <td>{{ item.clicks }}</td>
            <td>{{ item.atbs }}</td>
            <td>{{ item.orders }}</td>
            <td>{{ item.sum_price }}</td>
            <td>{{ item.cpm }}</td>
            <td>{{ item.sum }}</td>
            <td>{{ item.ctr }}</td>
            <td>{{ item.cpc }}</td>
            <td>{{ item.cr }}</td>
            <td>{{ item.cpa }}</td>
            <td>{{ item.appType }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default{
    props: {
        id: Number
    },
  data(){
    return {
      items_articles: [],
      items_days: [],
      campaign_data: [],
      loading: true
    }
  },
  methods: {
    loadData(){
      this.loading = true
      let post_data = {
        advertId: this.$props.id,
        user_id: this.$store.state.user_info.id
      }

      axios.post('https://tapwill.ru/api/get_adv_info.php', post_data).then((response)=>{
        this.campaign_data = response.data[0]
        this.items_articles = response.data[1]
        this.items_days = response.data[2]
        console.log(response.data)
        this.loading = false
    })
    },
  },
  created(){
    this.loadData()
  }
}
</script>